<template>
  <Layout navbarAbsolute navbarTransparent>
    <div class="ql-editor pb-0 px-0" v-if="current">
      <div
        :style="`height: 500px; background-size: cover; background-position:center;
                background-image: linear-gradient(to right, rgba(255,255,255,1), rgba(0,0,0,0)), url('${current.team_banner_pict}');`"
        class="w-100 d-flex align-items-center position-relative"
      >
        <h1
          style="color: #0b5087; font-weight: bolder; margin: 0 10vw; max-width: 500px"
          class="display-4"
        >{{ current.team_name }}</h1>
      </div>
      <div class="" style="background: #0b5087">
        <div class="container d-flex p-2 align-items-center" style="gap: 10px">
          <div class="mr-auto">
            <h3 class="text-white h1 font-weight-bolder">{{ current.team_role }}</h3>
            <h3 class="text-white h4">
              <i>{{ current.tc_name}}</i>
            </h3>
          </div>
          <a
            :href="`mailto: ${current.team_email}`"
            class="btn btn-icon rounded-circle"
            style="border:2px solid white;background: #08365a; color: white !important"
          >
            <i class="bx bx-envelope" style="font-size:24px"></i>
          </a>
          <a
            :href="current.team_redirect_mvp"
            class="btn btn-icon rounded-circle"
            style="border:2px solid white;background: #08365a; color: white !important"
          >
            <i class="bx bxl-linkedin" style="font-size:24px"></i>
          </a>
        </div>
      </div>

      <div class="my-5 container" style="padding: 0 10%">
        <p class="ql-editor" v-html="current.team_full_bio"></p>
      </div>

      <div class="" style="background: #ededed">
        <div class="container p-5">
          <div id="ABS-13" class="">
            <div class="h1 text-center font-weight-bolder">Other Team Members</div>
          </div>
          <div
            class="mx-auto my-1"
            style="width: 100px; height: 5px; background: #6fa6d2; border-radius: 5px"
          />

          <div class="row mt-4 justify-content-center">
            <div class="col-lg-3" v-for="(item,idx) in teams" :key="item.team_id">
              <router-link :to="`/team/${item.team_id}`" v-if="idx < 4">
                <div class="card card-team shadow-lg">
                  <img
                    :src="item.team_profile_pict"
                    class="w-100"
                    style="height: 400px; object-fit: cover; object-position: center"
                  />
                  <div class="p-2 detail mt-1">
                    <h2 style="color: #0b5087" class="h4 font-weight-bolder text-center">
                      {{ item.team_name }}
                    </h2>
                    <h2 style="margin-top:.5em" class="h6 text-secondary font-weight-bolder text-center">
                      {{ item.team_role }}
                    </h2>
                    <h2 style="font-size:12px" class="h6 text-secondary font-weight-bolder text-center">
                      {{ item.tc_name }}
                    </h2>
                    <div class="description">
                      <p v-html="item.team_desc" class="mt-2 text-justify"></p>
                      <div
                        style="background: #0b5087; color: white !important"
                        class="btn float-right mt-1"
                      >
                        Detail
                      </div>
                    </div>
                  </div>
                </div>
              </router-link>
            </div>
          </div>

          <!-- <div class="d-flex justify-content-center">
            <router-link
              to="/about-us#TEAMS"
              class="btn"
              style="background: #08365a; color: white !important"
            >
              Back To Our Teams
            </router-link>
          </div> -->
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layouts/main.vue";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Layout,
  },
  methods: {
    async getNextDatas() {
      let temp = await store.dispatch(`teams/getDataDetail`, {
        id: this.$route.params.id,
      });
      this.current = temp;
      this.$forceUpdate();
    },
    async getTeams() {
      let temp = await store.dispatch(`teams/getDataList`, {
        team: this.current.tc_id
      });
      this.teams = temp.filter(item => item.team_id != this.$route.params.id);
      this.$forceUpdate();
    },
  },
  computed: {
    id_par : function(){
        return this.$route.params.id;
    }
  },
  mounted() {
    this.getNextDatas();
    this.getTeams();
  },
  watch: {
    id_par: {
        deep: true,
        handler: function(){
            this.getTeams();
            this.getNextDatas();
        }
    },
    current(){
        this.getTeams();
    }
  },
  data() {
    return {
      moment,
      teams: '',
      current: '',
    };
  },
};
</script>

<style scoped>
.mask1 {
  -webkit-mask-image: linear-gradient(to bottom, transparent 0%, black 40%);
  mask-image: linear-gradient(to bottom, transparent 0%, black 90%);
}
.card-event {
  background-color: white;
  cursor: pointer;
}
.card-event:hover {
  background-color: rgb(228, 234, 255);
}

@keyframes card-in-anim {
  from {
    display: none;
    opacity: 0;
    bottom: -30px;
  }
  to {
    display: block;
    opacity: 1;
    bottom: 0px;
  }
}
@keyframes card-in-anim-scale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
@keyframes card-out-anim {
  from {
    border: 4px solid black;
    background: #f8f8f8;
    color: black;
  }
  to {
    border: 4px solid #575759;
    background: #121212;
    color: white;
  }
}

.card-team:hover .detail .description {
  animation-name: card-in-anim;
  animation-duration: 0.5s;
  display: block;
  opacity: 1;
  bottom: 0px;
}

.card-team:hover {
  animation-name: card-in-anim-scale;
  animation-duration: 0.5s;
  transform: scale(1.05);
  cursor: pointer;
}

.card-team {
  border-radius: 10px;
}
.card-team img {
  border-radius: 10px;
}

.card-team .detail .description {
  display: none;
  opacity: 0;
}
.card-team .detail {
  border-radius: 10px;
  /* display: none; */
  width: 100%;
  position: absolute;
  background: white;
  bottom: 0;
}
</style>
